var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"popup-products",attrs:{"id":"popup-products","centered":"centered","title":(_vm.product.id ? 'Modifier' : 'Ajouter') + " un produit"},on:{"ok":_vm.create},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"w-100 mx-0"},[(_vm.product.id)?_c('b-button',{staticClass:"float-left",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteProductLocal(_vm.product.id, _vm.product.label)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1):_vm._e(),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return ok()}}},[_vm._v(_vm._s(_vm.product.id ? "Modifier" : "Ajouter"))]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return cancel()}}},[_vm._v("Annuler")])],1)]}}])},[(_vm.isCreatingProduct)?_c('div',[_c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"loading-bg-inner"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"outer"}),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"inner"})])]),_c('div',{staticClass:"mt-5"},[_c('br'),_c('br'),_c('br'),_vm._v(" Chargement... ")])])]):_c('validation-observer',{ref:"formProduct"},[_c('b-row',[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-form-group',{staticStyle:{"flex":"1"},attrs:{"label":"Libellé *","label-for":"Libellé"}},[_c('validation-provider',{attrs:{"name":"Libellé","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"type":"text","id":"label","state":errors.length > 0 ? false : null,"autocomplete":"nope","aria-autocomplete":"nope"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.create.apply(null, arguments)}},model:{value:(_vm.product.label),callback:function ($$v) {_vm.$set(_vm.product, "label", $$v)},expression:"product.label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticStyle:{"flex":"1"},attrs:{"label":"Prix public *","label-for":"Prix public"}},[_c('validation-provider',{attrs:{"name":"Prix public","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"type":"number","id":"publicPrice","state":errors.length > 0 ? false : null,"autocomplete":"nope","aria-autocomplete":"nope","step":"0.01"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.create.apply(null, arguments)}},model:{value:(_vm.product.publicPrice),callback:function ($$v) {_vm.$set(_vm.product, "publicPrice", $$v)},expression:"product.publicPrice"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("€")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticStyle:{"flex":"1"},attrs:{"label":"Prix net *","label-for":"Prix net"}},[_c('validation-provider',{attrs:{"name":"Prix net","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"type":"number","id":"netPrice","state":errors.length > 0 ? false : null,"autocomplete":"nope","aria-autocomplete":"nope","step":"0.01"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.create.apply(null, arguments)}},model:{value:(_vm.product.netPrice),callback:function ($$v) {_vm.$set(_vm.product, "netPrice", $$v)},expression:"product.netPrice"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("€")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-form-group',{staticStyle:{"flex":"1"},attrs:{"label":"Unité *","label-for":"Unité"}},[_c('validation-provider',{attrs:{"name":"Unité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"type":"text","id":"unit","state":errors.length > 0 ? false : null,"autocomplete":"nope","aria-autocomplete":"nope"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.create.apply(null, arguments)}},model:{value:(_vm.product.unit),callback:function ($$v) {_vm.$set(_vm.product, "unit", $$v)},expression:"product.unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }